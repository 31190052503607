.react-datepicker__input-container input {
  height: 38px;
  width: 95%;
  padding: 0 0 0 10px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  outline: none;
  color: #000;
  font-size: 14px;
  font-weight: 400;
}
.history-calendar .react-datepicker__input-container input {
  color: transparent;
  font-size: 0;
}
.report .react-datepicker__input-container input {
  opacity: 0;
  height: 46px;
}
.history .react-datepicker{
  width: 100%;
  max-width: 500px;
  font-family: 'Public Sans', sans-serif;
  background: none;
  border: none;
  display: block;
  margin:0 auto;
}
.history .react-datepicker__header{
  background: none;
  border:none;
  padding: 0;
}
.history .react-datepicker__month-container{
  float: none;
}
.history .react-datepicker__month{
  margin: 0;
}
.history .react-datepicker__current-month{
  font-size: 14px;
  font-weight: 500;
  background: #FFFFFF;
  border: 1px solid #F0F0F0;
  border-radius: 4px;  
  padding: 12px;
  margin:0 10px;
  flex-grow: 1;
}
.history .react-datepicker__week{
  display: flex;
  justify-content: space-between;  
}
.datepicker__navigation{
  width: 40px;
  height: 40px;
  background: #0D6FF0;
  border-radius: 4px;
  box-sizing: border-box;
  border:none;
  cursor: pointer;
}
.history .react-datepicker__day-names{
  display: flex;
  justify-content: space-between;
  margin: 0;
}
.history .react-datepicker__day-name{
  width: 100%;
  max-width: 44px;
  height: 44px;
  font-size: 14px;
  font-weight: 500;
  line-height: 44px;
  margin:3.5px;  
}
.history .react-datepicker__day-name:first-child,
.history .react-datepicker__day-name:last-child{
  color:#FF4D4F;
}
.history .react-datepicker__day{
  position: relative;
  width: 44px;
  height: 44px;
  font-size: 14px;
  font-weight: 500;
  line-height: 44px;
  background: #FFFFFF;
  border: 1px solid #F0F0F0;
  border-radius: 4px;  
  margin:3.5px;
  box-sizing: border-box;
}
.history .react-datepicker__day--today{
  background: #FFFFFF;  
  color: #000;
  border: 1.5px solid #0D6FF0;
}
.history .react-datepicker__day--today:hover{
  background: #0D6FF0;
  color:#fff;
}
.history .react-datepicker__day--weekend{
  color: #FF4D4F;
}
.history .react-datepicker__day--outside-month{
  color: #F0F0F0;
}
.history .react-datepicker__day--selected{
  color: currentColor;
}

.worked-time:after{
  position: absolute;
  top:6px;
  right:6px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #0D6FF0;
  content:'';
}