.react-datepicker__day--keyboard-selected {
  color: inherit;
  background: none;
}

.calendarPicker.react-datepicker-popper {
  padding: 0;
}
.dayCalendarClass {
  z-index: 6;
}
.calendarPicker .react-datepicker {
  border-color:#D9D9D9;
  /* box-shadow: 0px 5px 10px 2px rgba(0,0,0,0.1);     */
}
.calendarPicker .react-datepicker__triangle {
  display: none;
}
.calendarPicker .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  color: #D9D9D9;
  background: none;
  cursor: pointer;
}

.calendarPicker .react-datepicker__current-month {
  font-size: 14px;
  font-weight: 400;
}

.calendarPicker .react-datepicker__header {
  background: none;
  border: none;
  padding: 0;
}

.calendarPicker .react-datepicker__day-names .react-datepicker__day-name{
  width: 36px;
  margin: 0;
}

.calendarPicker .react-datepicker__day-name:first-child,
.calendarPicker .react-datepicker__day-name:last-child{
  color:#FF4D4F;
}

.calendarPicker .react-datepicker__day--weekend{
  color: #FF4D4F;
}

.calendarPicker .react-datepicker__day {
  width: 24px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1.2;
  margin: 6px;
  border-radius: 2px;
}

.calendarPicker .react-datepicker__day--in-range {
  background: #0D6FF0;
  color:#fff;  
}

.calendarPicker .react-datepicker__day--today:hover{
  background: #0D6FF0;
  color:#fff;
}

.calendarPicker .react-datepicker__day--today{
  background: #FFFFFF;  
  color: #000;
  border: 1px solid #0D6FF0;
  box-sizing: border-box;
}
.calendarPicker .react-datepicker__day--selected {
  background: #0D6FF0;
  color:#fff;  
}

.modalPicker {
  width: 100%;
}

.modalPicker .react-datepicker {
  width: 100%;
  box-sizing: border-box;
}

.modalPicker .react-datepicker .react-datepicker__month-container {
  width: 100%;
}