.ReactInputVerificationCode__container {
    width: 100% !important;
}

.ReactInputVerificationCode__input {
    left: 0 !important;
    width: 0 !important;
    height: 0 !important;
}

.ReactInputVerificationCode__item {
    width: 68px !important;
    height: 68px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}